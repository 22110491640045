import request from "../utils/request";

/**
 * 查询所有合作方用于条件查询
 * @returns {AxiosPromise}
 */
export function clientInfoQueryAll() {
  return request({
    url: '/clientInfo/queryAll',
    method: 'get',

  })
}

/**
 * 分页查询合作方
 * @param clientCode
 * @param pageNumber
 * @param pageSize
 * @returns {AxiosPromise}
 */
export function clientInfoQueryByPage(clientCode, pageNumber, pageSize) {
  return request({
    url: '/clientInfo/queryByPage',
    method: 'get',
    params:{
      clientCode, pageNumber, pageSize
    }
  })
}

export function clientInfoInsert(clientName, clientTel) {
  return request({
    url: '/clientInfo/insert',
    method: 'post',
    data:{
      clientName, clientTel
    }
  })
}

export function clientInfoUpdate(clientCode, clientName, clientTel) {
  return request({
    url: '/clientInfo/update',
    method: 'post',
    data:{
      clientCode, clientName, clientTel
    }
  })
}

export function clientInfoDeleteByCode(clientCode) {
  return request({
    url: '/clientInfo/deleteByCode',
    method: 'DELETE',
    params:{
      clientCode
    }
  })
}

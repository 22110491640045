<template>
  <el-dialog :title="'审核申请'"
             :close-on-click-modal="false"
             :visible.sync="visible"
             width="650px">
    <el-form :model="dataForm" label-width="100px" ref="applyForm">
      <el-form-item label="申请方名称:" prop="applyName">
        <div>{{ dataForm.applyName }}</div>
      </el-form-item>
      <el-form-item label="申请数量:" prop="applyNum">
        <div>{{ dataForm.applyNum }}</div>
      </el-form-item>
      <el-form-item label="申请时间:" prop="applyTime">
        <div>{{ dataForm.applyTime }}</div>
      </el-form-item>
      <el-form-item label="备注说明:" prop="remark">
        <div>{{ dataForm.remark }}</div>
      </el-form-item>
      <div class="dashed-line"></div>
      <el-form-item label="审核意见">
        <div>
          <el-button type="primary" @click="passApply()">通过</el-button>
          <el-button @click="refuseApply()">拒绝</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {applyAudit} from "../../api/apply";

export default {
  name: "Do-apply-review",
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        applyName: '学易达',
        applyNum: 1000,
        applyTime: '2022-01-01',
        remark: '请公司开放入库权限'
      }
    }
  },
  methods: {
    init(id, clientName, number, creationTime, remark) {
      console.log(id);
      this.dataForm.id = id
      this.visible = true
      this.$nextTick(() => {
        this.$refs.applyForm.resetFields()
        this.dataForm.applyName = clientName
        this.dataForm.applyNum = number
        this.dataForm.applyTime = creationTime
        this.dataForm.remark = remark
      })
    },

    /**
     * 同意申请
     */
    passApply() {
      this.$confirm('确定要通过该申请?','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        applyAudit(this.dataForm.id, 1).then((res) => {
          if (res && res.code === 1){
            this.$message({
              message: '通过申请成功!',
              type: 'success',
              duration: 2000,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList') //刷新父组件的表格内容
              }
            })
          } else {
            this.$message.error(res.data[0])
          }
        })

      })

    },

    /**
     * 拒绝
     */
    refuseApply() {
      this.$confirm('确定要拒绝该申请?','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=> {
        applyAudit(this.dataForm.id, 2).then((res) => {
          if (res && res.code === 1){
            this.$message({
              message: '拒绝申请成功!',
              type: 'success',
              duration: 2000,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList') //刷新父组件的表格内容
              }
            })
          } else {
            this.$message.error(res.data[0])
          }
        })
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.dashed-line {
  width: 100%;
  height: 0;
  border: 1px dashed #797979;
  margin-bottom: 30px;
}

.el-button{
  width: 120px;
  margin-left: 30px;
}
</style>

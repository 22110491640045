import { render, staticRenderFns } from "./ApplyReview.vue?vue&type=template&id=2a6c4156&scoped=true&"
import script from "./ApplyReview.vue?vue&type=script&lang=js&"
export * from "./ApplyReview.vue?vue&type=script&lang=js&"
import style0 from "./ApplyReview.vue?vue&type=style&index=0&id=2a6c4156&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a6c4156",
  null
  
)

export default component.exports
<!--
 @fileName    : ApplyReview.vue
 @author      : llz
 @date        : Created in 2022-04-15 15:04:14
 @description : 申请审核
 @version     :

 鹿乃yyds!!!
-->
<template>
  <el-card>
    <main-header main-title="申请审核"></main-header>
    <div class="apply-review">
      <el-form :inline="true" ref="applySearchForm" :model="searchForm">
        <el-form-item prop="clientCode">
          <el-select v-model="searchForm.clientCode" placeholder="请选择申请方" filterable clearable>
            <el-option v-for="item in partnerList" :value="item.clientCode" :label="item.clientName" :key="item.clientCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="applyStatus">
          <el-select v-model="searchForm.applyStatus" placeholder="请选择申请状态" clearable>
            <el-option label="待审核" :value="0"></el-option>
            <el-option label="已通过" :value="1"></el-option>
            <el-option label="已拒绝" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请时间" prop="startTime">
          <el-date-picker v-model="searchForm.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间"></el-date-picker>
         </el-form-item>
          <div class="line">——</div>
        <el-form-item prop="endTime">
          <el-date-picker v-model="searchForm.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间"></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button @click="doSearch()">查询</el-button>
          <el-button type="success" @click="resetSearch('applySearchForm')">重置</el-button>
        </el-form-item>
      </el-form>

      <el-table :data="applyTableData" border v-loading="dataListLoading" style="width: 100%">
        <el-table-column prop="clientName" header-align="center" align="center" label="申请方名称"></el-table-column>
        <el-table-column prop="number" header-align="center" align="center" label="申请数量"></el-table-column>
        <el-table-column prop="creationTime" header-align="center" align="center" label="申请时间"></el-table-column>
        <el-table-column prop="accountName" header-align="center" align="center" label="审核人"></el-table-column>
        <el-table-column prop="updateTime" header-align="center" align="center" label="审核时间"></el-table-column>
        <el-table-column prop="state" header-align="center" align="center" label="审核状态" :formatter="stateFormatter"></el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作" v-if="userType === 1">
          <template v-slot="scope">
            <el-button type="text" size="small" @click="openDoApplyReviewDialog(scope.row)" v-if="scope.row.state === 0">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalSize"
          layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>
    <do-apply-review v-if="doApplyReviewVisible" ref="doApplyReview" @refreshDataList="refreshApplyDataList()"></do-apply-review>
  </el-card>
</template>

<script>
import DoApplyReview from "./Do-apply-review";
import {ApplyQueryByPage} from "../../api/apply";
import {getUserType} from "../../utils/cookie";
import {clientInfoQueryAll} from "../../api/partner";
import MainHeader from "../../components/layout/mainHeader";

export default {
  name: "ApplyReview",
  components: {MainHeader, DoApplyReview},
  data() {
    return {
      searchForm: {
        clientCode: '',
        applyStatus: '',
        startTime: '',
        endTime: ''
      },
      //点击查询按钮后保存并带入提交的搜索条件，防止用户输入条件后直接去切换分页导致页码溢出
      submitSearchForm: {
        clientCode: '',
        applyStatus: '',
        startTime: '',
        endTime: ''
      },
      partnerList: [],

      applyTableData: [

      ],
      pageIndex: 1, //页码(从1开始)
      pageSize: 10, //每页条数
      totalSize: 0, //总条目数
      dataListLoading: false,
      doApplyReviewVisible: false
    }
  },

  computed: {
    userType() {
      return Number(getUserType())
    }
  },

  activated() {
    this.getPartnerList()
    this.getApplyDataList()
    console.log("activated!!!")
  },

  methods: {
    getPartnerList() {
      clientInfoQueryAll().then((res) => {
        console.log(res)
        if (res && res.length > 0) {
          this.partnerList = res
          // this.$set()
        }
      })
    },
    getApplyDataList() {
      this.dataListLoading = true
      ApplyQueryByPage(this.submitSearchForm.clientCode, this.submitSearchForm.applyStatus, this.submitSearchForm.startTime, this.submitSearchForm.endTime, this.pageIndex,
          this.pageSize).then((res) => {
            if (res && res.data.content){
              this.applyTableData = res.data.content
              this.totalSize = res.data.totalElements
            } else {
              this.applyTableData = []
              this.totalSize = 0
            }
            this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getApplyDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getApplyDataList()
    },

    //点击查询
    doSearch() {
      //TODO 更新搜索条件
      this.submitSearchForm.clientCode = this.searchForm.clientCode
      this.submitSearchForm.applyStatus = this.searchForm.applyStatus
      this.submitSearchForm.startTime = this.searchForm.startTime
      this.submitSearchForm.endTime = this.searchForm.endTime
      this.pageIndex = 1
      this.getApplyDataList()
    },

    //重置搜索条件查询
    resetSearch(formName) {
      this.$refs[formName].resetFields()
      this.submitSearchForm.clientCode = ''
      this.submitSearchForm.applyStatus = ''
      this.submitSearchForm.startTime = ''
      this.submitSearchForm.endTime = ''
      this.pageIndex = 1
      this.getApplyDataList()
    },

    openDoApplyReviewDialog(row) {
      this.doApplyReviewVisible = true
      this.$nextTick(() => {
        this.$refs.doApplyReview.init(row.id, row.clientName, row.number, row.creationTime, row.remark)
      })
    },

    refreshApplyDataList() {
      this.pageIndex = 1
      this.getApplyDataList()
    },


    stateFormatter(row, column, cellValue){
      if (cellValue === 0){
        return '待审核'
      } else if (cellValue === 1){
        return '已通过'
      } else if (cellValue === 2){
        return '已拒绝'
      }
      return '';
    }
  }
}
</script>

<style lang="scss" scoped>
.el-card {
  height: 100%;
  //min-width: 1260px;
  overflow: auto;

  .apply-review {
    padding: 15px 10px;
  }
}

.line {
  display: inline-block;
  margin-top: 8px;
  margin-right: 20px;
}

.el-form--inline .el-form-item {
  margin-right: 20px;
}
</style>

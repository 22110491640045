import request from "../utils/request";

/**
 * 查询申请列表
 * @param clientCode 客户编号
 * @param state 审核状态
 * @param start 开始时间
 * @param stop 结束时间
 * @param pageNumber 页码（从0开始）
 * @param pageSize 每页条数
 * @returns {AxiosPromise}
 * @constructor
 */
export function ApplyQueryByPage(clientCode, state, start, stop, pageNumber, pageSize) {
  return request({
    url: '/storageApply/queryByPage',
    method: 'get',
    params:{
      clientCode, state,start, stop, pageNumber, pageSize
    }
  })
}

/**
 * 审核申请
 * 请求头Header要传递token，在拦截器里应该已经添加
 * @param id
 * @param state 1通过 2拒绝
 * @returns {AxiosPromise}
 */
export function applyAudit(id, state) {
  return request({
    url: '/account/updateAudit',
    method: 'get',
    params: {
      id, state
    }
  })
}

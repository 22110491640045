<template>
  <div class="main-header">
    <span class="header-title">{{ mainTitle }}</span>
    <div class="btn-list">
      <slot name="btn"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "mainHeader",
  props: ['mainTitle']
}
</script>

<style lang="scss" scoped>
.main-header {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border-bottom: 1px solid #f6f6f6;
  color: #333;
  border-radius: 2px 2px 0 0;
  font-size: 18px;
  position: relative;

  .header-title {
    color: #333;
    margin-left: 10px;
  }

  .btn-list {
    height: 100%;
    float: right;
    display: flex;
    align-items: center;

    button {
      margin-left: 15px;
    }

  }

  &::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 20px;
    background-color: #379fff;
    left: 15px;
    top: 50%;
    margin-top: -10px;
  }
}
</style>
